import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import useConfig from 'src/common/hooks/useConfig';
import LoginForm from 'src/components/LoginModal/LoginForm';
import { MetadataRenderer } from 'src/components/MetaDescription/MetadataRenderer';
import { Header } from 'src/modules/Login/Components/Header';

const Container = styled.div`
  background-color: #fafafa;
  min-height: 100vh;
`;

const InnerWrapper = styled.div`
  width: 100%;
  max-width: 360px;
  margin: auto;

  .aries-textfield {
    margin-top: 20px;
  }
`;

const HeaderWrapper = styled.div`
  div {
    position: relative;
  }
`;

const messages = defineMessages({
  title: {
    id: 'login-page.title',
    defaultMessage: 'Login to Glints Now',
  },
  description: {
    id: 'login-page.description',
    defaultMessage:
      'Login to Glints now and get recommendation of jobs that suitable for you. Many vacancies from various best companies in Indonesia.',
  },
});

const LoginPage = () => {
  const { CANDIDATE_BASE, BASENAME } = useConfig();
  const { formatMessage } = useIntl();
  return (
    <Container>
      <MetadataRenderer
        title={formatMessage(messages.title)}
        description={formatMessage(messages.description)}
        canonical={`${CANDIDATE_BASE}${BASENAME !== '/' ? BASENAME : ''}/login`}
      />
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <InnerWrapper>
        <LoginForm />
      </InnerWrapper>
    </Container>
  );
};

export default LoginPage;
