import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as S from './Header.sc';

export const Header: React.FC<
  React.PropsWithChildren<Record<string, unknown>>
> = () => {
  return (
    <S.Container>
      <S.TitleWrapper>
        <S.Title>
          <FormattedMessage
            id="login-page_login-title"
            defaultMessage="Welcome back!"
          />
        </S.Title>
      </S.TitleWrapper>

      <S.TitleWrapper>
        <S.SubTitle>
          <FormattedMessage
            id="login-page_login-subtitle"
            defaultMessage="Login to your Glints account."
          />
        </S.SubTitle>
      </S.TitleWrapper>
    </S.Container>
  );
};
