import { Typography } from 'glints-aries';
import styled from 'styled-components';

export const TitleWrapper = styled.div`
  margin-bottom: 20px;
`;

export const Container = styled.div`
  text-align: center;

  #third-party-login-info {
    position: static;
    top: 0px;
    margin-bottom: 50px;
  }
`;

export const Title = styled(Typography.Title).attrs({ tag: 'h1' })`
  font-size: 30px;
  font-weight: bold;
`;

export const SubTitle = styled.p`
  font-size: 16px;
`;
